module.exports = [
    {
        "id" : 1,
        "title" : "Why You Should Consider Back Office Outsourcing for Logistics",
        "body" : "In today’s fast-paced logistics industry, efficiency and agility are critical to maintaining a competitive edge. While the focus often falls on optimizing supply chains and ensuring timely deliveries, the importance of back-office functions cannot be overlooked. These essential tasks—ranging from data entry and billing to customer service and inventory management—form the backbone of any successful logistics operation. However, managing these functions in-house can be resource-intensive and detract from your core business activities. This is where back office outsourcing offers a strategic advantage.",
        "author" : "Admin",
        "imgUrl" : require("./../../../assets/images/blog01.jpg")
      },
      {
        "id" : 2,
        "title" : "Why Choose the Philippines, Especially Clark, for Your BPO Setup",
        "body" : "There a",
        "author" : "Admin", 
        "imgUrl" :  require("./../../../assets/images/blog2.jpg")
      },
]