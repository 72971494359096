import React from "react";
import SubPageBanner from "../../components/sub_banner";

function Privacy() {
    return (
        <>

        <SubPageBanner name="Privacy Policy" />

      <div className="container-fluid about py-5">
            <div className="container">
                <div className="row g-5">
                    <div className="col-xl-12 wow fadeInLeft" data-wow-delay="0.2s">
                        <div className="about-item-content bg-white rounded">
                            <h1 className="text-primary overline">Privacy Policy | Agile Beyond</h1>
                            <p>Effective Date:  September 2024</p> 
                        </div>
                        <div className="my-5">
                             <h5>1.  Introduction</h5>
                             <p className="ms-3 fw-light">Agile Beyond ("we," "us," or "our") respects and values your privacy. This Privacy Policy outlines our commitment to protecting your personal information in compliance with the Philippines' Data Privacy Act of 2012 (Republic Act No. 10173) and its Implementing Rules and Regulations.</p>
                             <h5>2. Information We Collect</h5>
                             <ul className="ms-3 ps-4 navTabDecimal fw-light">
                                <li>
                                    Personal Information: We may collect personal information that you provide to us voluntarily, including but not limited to your name, email address, phone number, and any other details you provide when contacting us, subscribing to our newsletter, or filling out a form on our website.
                                </li>
                                <li>
                                    Sensitive Personal Information: We do not intentionally collect sensitive personal information such as information about your race, religion, health, or sexual orientation. If such information is provided to us, it will be treated with the highest degree of confidentiality and security.
                                </li>
                                <li>
                                    Usage Data: We may automatically collect information on how our website is accessed and used. This may include your IP address, browser type, operating system, pages visited, and the date and time of your visit.
                                </li>
                                <li>
                                    Cookies: We use cookies and similar technologies to track the activity on our website and hold certain information. Cookies are files with a small amount of data that may include an anonymous unique identifier. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.
                                </li>
                             </ul>

                             <h5 className="mt-4">3. How We Use Your Information</h5>
                             <p className="ms-3 fw-light">We collect and use your personal data for the following purposes:</p>
                            
                             <ul className="ms-3 ps-4 navTabDecimal fw-light">
                                <li>
                                    To provide, operate, and maintain our website and services
                                </li>
                                <li>To improve our website and services</li>
                                <li>To personalize your experience on our website</li>
                                <li>To communicate with you, including responding to inquiries, and sending newsletters or promotional materials</li>
                                <li>To monitor and analyze usage patterns to enhance the user experience</li>
                                <li>To comply with legal and regulatory requirements</li>
                                <li>To detect, prevent, and address technical issues</li>
                             </ul>

                             <h5 className="mt-4">4. Legal Basis for Processing</h5>
                             <p className="ms-3 fw-light">We process your personal information based on the following legal grounds:</p>
                             <ul className="ms-3 ps-4 navTabDecimal fw-light">
                                <li><span>Consent</span> We process your data based on the consent you provide when you voluntarily submit your information through our website.</li>
                                <li><span>Contractual Necessity: </span>We process your data to fullfil our contractual obligations to you.</li>
                                <li><span>Legal Obligation:</span> We may process your data to comply with legal obligations.</li>
                                <li><span>Legitimate Interest:</span> We may process your data based on our legitimate interests in operating and improving our website and services, provided that these interests are not overridden by your rights and interests.</li>
                             </ul >

                             <h5 className="mt-4"> 5. How We Share Your Information</h5>
                             <p className="ms-3 fw-light">We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except in the following circumstances:</p>
                             <ul className="ms-3 ps-4 navTabDecimal fw-light">
                                <li><span>Service Providers: </span>
                                    We may share your information with trusted third-party service providers who assist us in operating our website, conducting our business, or servicing you. These providers are contractually obligated to keep your information confidential and secure.
                                </li>
                                <li><span>Legal Requirements: </span>
                                    We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or government agency).
                                </li>
                            </ul >

                            <h5 className="mt-4">6. Data Security</h5>
                             <p className="ms-3 fw-light">We implement appropriate technical and organizational measures to protect your personal data against unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the Internet or method of electronic storage is 100% secure, and we cannot guarantee its absolute security.
                            </p>

                            <h5 className="mt-4">7. Data Retention</h5>
                            <p className="ms-3 fw-light">We will retain your personal data only for as long as 
                                necessary to fulfill the purposes for which it was collected or as 
                                required by law. Once the data is no longer necessary, we will securely delete or anonymize it.</p>
                            
                            <h5 className="mt-4">8. Your Rights</h5>
                            <p className="ms-3 fw-light">Under the Data Privacy Act, you have the following rights:</p>
                            <ul className="ms-3 ps-4 navTabDecimal fw-light">
                                <li><span>Right to Be Informed:</span> 
                                    You have the right to be informed about how your personal data is being processed.
                                </li>
                                <li><span>Right to Access: </span>
                                    You have the right to access your personal data held by us.
                                </li>
                                <li><span>Right to Rectification: </span>
                                    You have the right to correct any inaccuracies or errors in your personal data.     
                                </li>
                                <li><span>Right to Erasure:</span> 
                                    You have the right to request the deletion or removal of your personal data.
                                </li>
                                <li><span>Right to Object: </span>
                                    You have the right to object to the processing of your personal data under certain conditions.
                                </li>
                                <li><span>Right to Data Portability: </span> 
                                    You have the right to obtain a copy of your personal data in a structured, commonly used, and machine-readable format.
                                </li>
                                <li><span>Right to File a Complaint:</span> 
                                    You have the right to file a complaint with the National Privacy Commission if you believe your data privacy rights have been violated.
                                </li>
                            </ul >
                            <p>To exercise these rights, please contact us at [roland.schaub@agilebeyond.net].</p>

                            <h5 className="mt-4">9. Third-Party Links</h5>
                            <p className="ms-3 fw-light"> Our website may contain links to third-party websites. We are not responsible for the privacy practices or the content of these third-party sites. We encourage you to review the privacy policies of any third-party websites you visit.
                           </p>

                           
                           <h5 className="mt-4">10. Changes to This Privacy Policy</h5>
                           <p className="ms-3 fw-light"> This Privacy Policy is tailored to comply with the Philippines' Data Privacy Act (Republic Act No. 10173).
                            We may update our Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. Any changes will be posted on this page with an updated effective date. We encourage you to review this Privacy Policy periodically.  
                        
                          </p>

                          <h5 className="mt-4"> 11. Contact Us</h5>
                           <p className="ms-3 fw-light"> If you have any questions about this Privacy Policy or our data practices, please contact us at:
                          </p>
                          <p className="ms-3 fw-light">📧 Email: roland.schaub@agilebeyond.net  </p>
                          <p className="ms-3 fw-light">📍 Address: 
                            La Grande Residence, Ph2, Unit 1402, 46 Sarmiento St. Plaridel 1 Subdivision, Malabanias, Angeles City, 2009, Philippines
                          </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </>
  );
}

export default Privacy;
